import { Text, Flex, Group, Title, Badge, Anchor } from "@mantine/core";
import { Logo, LogoType } from "./logo";

export interface WorkItemProps {
  type: LogoType,
  title: string,
  subtitle: string,
  url?: string,
  buttonLabel: string,
}

export default function WorkItem(props: WorkItemProps) {
  const { type, title, subtitle, url, buttonLabel } = props;
  return (
    <Flex
      mih={50}
      gap="md"
      justify="space-between"
      align="center"
      direction="row"
      wrap="nowrap"
    >
      <Group>
        <Logo type={type} />
        <div >
          <Title order={4}>{title}</Title>
          <Text c="dimmed">{subtitle}</Text>
        </div>
      </Group>
      {!url && <Badge>{buttonLabel}</Badge>}
      {url && <Anchor href={url} target='blank'>
        <Badge>{buttonLabel}</Badge>
      </Anchor>}
    </Flex>
  );
}