enum Path {
    home = "/",
    terms = "/terms",
    privacy = "/privacy",
    support = "/support"
}

export function isValidPath(path: string) {
    return Object.values(Path).includes(path as Path);
}

export default Path;