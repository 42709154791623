import bigbrain from "../assets/bigbrain.png";
import artemis from "../assets/artemis.png";
import memlog from "../assets/memlog.png";
import nomotasks from "../assets/nomotasks.png";

export type LogoType = 'bigbrain' | 'artemis' | 'nomotasks' | 'memlog';

interface LogoProps {
  type: LogoType,
}

const logos = {
  'bigbrain': bigbrain,
  'artemis': artemis,
  'memlog': memlog,
  'nomotasks': nomotasks,
};

export function Logo(props: LogoProps) {
  const { type } = props ?? {};
  const width = "70", height = "70", borderRadius = "20%";
  return (
    <img src={logos[type]} height={height} width={width} style={{ borderRadius }} alt="Logo" />
  );
}