import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import App from "./App";
import Path from "./constants/path";
import HomeView from "./views/homeView";
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path={Path.home} element={<App />}>
          <Route path={Path.home} element={<HomeView />} />
          <Route path='*' element={<Navigate to={Path.home} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);