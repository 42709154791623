import { Title, Text, Stack, Center, Divider, Group } from "@mantine/core";
import WorkItem, { WorkItemProps } from "../components/workItem";
import profilepic from "../assets/profile.jpg";
import { IconBrandGithub, IconBrandLinkedin } from "@tabler/icons-react";

const workItemData: WorkItemProps[] = [
  {
    type: "artemis",
    title: "Artemis",
    subtitle: "Next-gen Reddit client",
    buttonLabel: 'IN DEVELOPMENT',
  },
  {
    type: "memlog",
    title: "Memlog",
    subtitle: "Micro-journaling for better memory",
    url: 'https://testflight.apple.com/join/dfGcc4vG',
    buttonLabel: 'TEST',
  },
  {
    type: "bigbrain",
    title: "Big Brain AI Chat",
    subtitle: "AI Chat App Powered by Chat GPT",
    url: 'https://apps.apple.com/us/app/id6448490112',
    buttonLabel: 'VIEW',
  },
  {
    type: "nomotasks",
    title: "Nomotasks",
    subtitle: "Task management made easy",
    url: 'https://apps.apple.com/us/app/id1584921857',
    buttonLabel: 'VIEW',
  },
];

const appDescription = "Robust experience in mobile application development and third-party integrations. Proficient in Flutter, cloud, teamwork and more. Driven by a user-focused design and approach.";
export default function HomeView() {
  return (
    <Center>
      <Stack>
        <Center>
          <img src={profilepic} height={100} width={100} style={{ borderRadius: '100%' }} alt="Logo" />
        </Center>
        <Center>
          <Title order={1}>Dominic DeAntonio</Title>
        </Center>
        <Center>
          <Title order={3}>Seasoned Fullstack Software Engineer</Title>
        </Center>
        <Center>
          <div style={{ maxWidth: "500px" }}>
            <Text c="dimmed">{appDescription}</Text>
          </div>
        </Center>
        <Group position="center">
          <a href='https://www.linkedin.com/in/dominic-deantonio/' target="blank">
            <IconBrandLinkedin size={50} />
          </a>
          <a href='https://github.com/dominic-deantonio' target="blank">
            <IconBrandGithub size={50} />
          </a>
        </Group>
        <Center>
          <Text c="dimmed">Contact me on LinkedIn.</Text>
        </Center>
        <Divider my="sm" />
        <Title order={3}>Recent Work</Title>
        {
          workItemData.map(e => <WorkItem {...e} />)
        }
      </Stack>
    </Center>
  );
}