import { Outlet } from "react-router";
import { Center } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Scaffold } from "./components/scaffold";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: Infinity,
    },
  },
});
export default function App() {
  return (
    <Center>
      <div style={{ maxWidth: "1440px" }}>
        <QueryClientProvider client={queryClient}>
          <div style={{ width: "100%", position: "relative" }}>
            <Scaffold>
              <Outlet />
            </Scaffold>
          </div>
        </QueryClientProvider>
      </div>
    </Center>
  );
}