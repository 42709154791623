import { Anchor, AppShell, Center, Group, Header, Space } from "@mantine/core";

export function Scaffold(props: any) {

  return (
    <>
      {/* <Header height={"40"}>
        <Space h="xs" />
        <Center>
          <Group >
            <Anchor href="/">
              Home
            </Anchor>
            <Anchor href="/privacy">
              Privacy
            </Anchor>
            <Anchor href="/terms">
              Terms
            </Anchor>
            <Anchor href="/support">
              Support
            </Anchor>
          </Group>
        </Center>
      </Header> */}
      <Center>
        {props.children}
      </Center>
    </>
  );
}